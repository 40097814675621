import React from "react";
import {AxiosError} from "axios";
import {useNavigate} from "react-router-dom";
import {unwrapResult} from "@reduxjs/toolkit";

import {getUserRoles} from "../auth/state/tokenStorage";
import ErrorBoundary from "../common/errors/ErrorBoundary";
import resourcesSlice, {getResourcesForCurrentUser} from "../resources/state";
import {getCurrentUser} from "../users/state/state";
import NavMenu from "./NavMenu";
import navigationSlice from "./state";
import {extractServerError} from "../common/helpers";
import {NO_RESOURCES_AVAILABLE_ERROR} from "../common/constants";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import * as routes from "../navigation/routes";

const NavMenuContainer = () => {
    const dispatch = useAppDispatch();
    const { noResourcesAvailable, resources, selectedResource } = useAppSelector((state) => state.resources);
    const { redirectToLogin } = useAppSelector((state) => state.auth);
    const { menuToggled } = useAppSelector((state) => state.navigation);

    const [error, setError] = React.useState<string | undefined>(undefined);
    const [loading, setLoading] = React.useState(false);
    const navigate = useNavigate();

    React.useEffect(() => {
        let isMounted = true;

        if (redirectToLogin) {
            console.log(`Redirecting to login as redirectToLogin is set to`, redirectToLogin);
            navigate(routes.LOGIN_ROUTE, {replace: true});
        } else {
            setLoading(true);
            if (noResourcesAvailable) {
                setLoading(false);
                setError(NO_RESOURCES_AVAILABLE_ERROR);
            } else {
                Promise.all([
                    dispatch(getCurrentUser()).then(unwrapResult),
                    dispatch(getResourcesForCurrentUser()).then(unwrapResult)
                ]).then(() => {
                    if (isMounted) {
                        setLoading(false);
                        setError(undefined);
                    }
                }).catch((err) => {
                    if (isMounted) {
                        setLoading(false);
                        setError(extractServerError(err as AxiosError));
                    }
                });
            }
        }

        // Cleanup: do not set state when this component is not mounted anymore
        return () => {isMounted = false};
    }, [redirectToLogin, noResourcesAvailable]);

    const handleResourceSelection = (id: number) => {
        const selectedResource = resources.find((r) => r.id === id, 10);

        if (selectedResource) {
            dispatch(resourcesSlice.actions.selectResource(selectedResource));
            dispatch(navigationSlice.actions.toggleMenuOff());
        }
    }

    return (
        <ErrorBoundary>
            <NavMenu
                resources={resources}
                selectedResource={selectedResource}
                handleResourceSelection={handleResourceSelection}
                userRoles={getUserRoles()}
                menuToggled={menuToggled}
                toggleMenuOn={() => dispatch(navigationSlice.actions.toggleMenuOn())}
                toggleMenuOff={() => dispatch(navigationSlice.actions.toggleMenuOff())}
                resourcesLoading={loading}
                error={error}
            />
        </ErrorBoundary>
    );
};

export default NavMenuContainer;

import React from "react";
import {Container} from "reactstrap";
import FormSpinner from "./FormSpinner";

const Spinner: React.FC = () => {
    return (
        <div className="vertical-center">
            <Container fluid>
                <FormSpinner/>
            </Container>
        </div>
    );
};

export default Spinner;

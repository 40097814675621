import React from "react";

import classNames from "classnames";
import {Col, Container, Row} from "reactstrap";
import api from "../../api/api";

interface IErrorBoundaryState {
    hasError: boolean;
}

class ErrorBoundary extends React.Component<Record<string, unknown>, IErrorBoundaryState> {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    public static getDerivedStateFromError(error: Error) {
        console.error(error);
        api.reportError({error}).then().catch((err) => console.error(err));
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    constructor(props: Record<string, unknown>) {
        super(props);
        this.state = {
            hasError: false,
        };
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    public render() {
        if (this.state.hasError) {
            return (
                <div className="vertical-center">
                    <Container fluid>
                        <Row className="mx-auto mt-5">
                            <Col md={{size: 6, offset: 3}} className="text-center">
                                <h1 className={classNames("text-center", "error")}>
                                    Something went wrong, please try again later
                                </h1>
                            </Col>
                        </Row>
                    </Container>
                </div>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;

import {getToken, TokenTypes} from "./tokenStorage";

export const authHeader = (): Record<string, string> => {
    const accessToken = getToken(TokenTypes.ACCESS_TOKEN);

    if (accessToken) {
        return {"API-Authorization": `Bearer ${accessToken}`};
    } else {
        return {};
    }
};

import axios, {AxiosError, AxiosInstance, AxiosResponse} from "axios";
import axiosRetry from 'axios-retry';
import createAuthRefreshInterceptor, {AxiosAuthRefreshRequestConfig} from "axios-auth-refresh";
import * as sourceStackTrace from 'sourcemapped-stacktrace';

import {authHeader} from "../auth/state/header";
import {deleteToken, getToken, saveToken, TokenTypes} from "../auth/state/tokenStorage";
import {
    ActivateUserAPIRequest,
    ActivateUserAPIResponse,
    ApproveAllocationRequestAPIRequest,
    ApproveAllocationRequestAPIResponse,
    CreateAllocationAPIRequest,
    CreateAllocationAPIResponse,
    CreateUserAPIRequest,
    CreateUserAPIResponse,
    DeleteAllocationAPIRequest,
    DeleteAllocationAPIResponse,
    GetAuditEventsForCategoryAPIRequest,
    GetAuditEventsForCategoryAPIResponse,
    GetCurrentUserAPIResponse,
    GetEventsForYearAPIRequest,
    GetEventsForYearAPIResponse,
    GetMyApprovalsAPIRequest,
    GetMyApprovalsAPIResponse,
    GetMyRequestsAPIRequest,
    GetMyRequestsAPIResponse, GetPendingAllocationRequestsAPIRequest, GetPendingAllocationRequestsAPIResponse,
    GetResourcesForCurrentUserAPIResponse,
    GetUpcomingEventsAPIRequest,
    GetUpcomingEventsAPIResponse,
    GetUsersForResourceAPIRequest,
    GetUsersForResourceAPIResponse,
    GetValidTimezonesAPIResponse,
    LoginAPIRequest,
    LoginAPIResponse,
    LogoutAPIResponse,
    NewAllocationRequestAPIRequest,
    NewAllocationRequestAPIResponse,
    ReactivateUserAPIRequest,
    ReactivateUserAPIResponse,
    RefreshTokensAPIResponse,
    RejectAllocationRequestAPIRequest,
    RejectAllocationRequestAPIResponse, 
    ReleaseAllocationRequestAPIRequest, 
    ReleaseAllocationRequestAPIResponse,
    ReportErrorAPIRequest,
    ReportErrorAPIResponse,
    ResetPasswordAPIRequest,
    ResetPasswordAPIResponse,
    RevokeAccessAPIRequest,
    RevokeAccessAPIResponse,
    SendPasswordResetLinkAPIRequest,
    SendPasswordResetLinkAPIResponse,
    UpdateAllocationAPIRequest,
    UpdateAllocationAPIResponse,
    UpdateCurrentUserAPIRequest,
    UpdateCurrentUserAPIResponse,
    UpdateUserRolesAPIRequest,
    UpdateUserRolesAPIResponse,
    UpdateResourceAPIRequest,
    UpdateResourceAPIResponse
} from "./types";
import authSlice from "../auth/state/state";

const skipAuthRefresh: AxiosAuthRefreshRequestConfig = {
    skipAuthRefresh: true,
};

class API {
    private readonly api: AxiosInstance;

    constructor() {
        this.api = axios.create({
            baseURL: "/api/",
            responseType: "json",
            timeout: 10000
        });

        this.api.interceptors.request.use((config: AxiosAuthRefreshRequestConfig) => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment,no-param-reassign
            config.headers = {...config.headers, ...authHeader()};

            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            return config;
        });

        createAuthRefreshInterceptor(this.api, this.refreshAuthLogic, {pauseInstanceWhileRefreshing: false});

        axiosRetry(this.api, {
            retries: 5,
            retryDelay: axiosRetry.exponentialDelay,
            retryCondition: this.shouldRetryRequest
        });
    }

    /*
    * Helper method for testing, not available in production.
    * */
    public testSetup() {
        if (process.env.NODE_ENV === "development") {
            this.api.defaults.baseURL = "http://localhost:3000/api/";
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            this.api.defaults.adapter = require('axios/lib/adapters/http');
        }
    }

    public reportError({error}: ReportErrorAPIRequest): ReportErrorAPIResponse {
        return new Promise(((_, resolve) => {
            sourceStackTrace.mapStackTrace(error.stack, (stackFrames) => {
                return resolve(this.api.post("/error", {name: error.name, message: error.message, stack: stackFrames},
                    {baseURL: "/", ...skipAuthRefresh}));
            });
        }));
    }

    public login({email, password}: LoginAPIRequest): LoginAPIResponse {
        const data = new FormData();
        data.set("username", email);
        data.set("password", password);

        return this.api.post("/auth/token", data, skipAuthRefresh);
    }

    public logout(): LogoutAPIResponse {
        return this.api.post("/auth/logout", {}, skipAuthRefresh);
    }

    public getEventsForYear({resourceId, year}: GetEventsForYearAPIRequest): GetEventsForYearAPIResponse {
        return this.api.get(`/resources/${resourceId}/events/${year}`);
    }

    public getUpcomingEvents({resourceId}: GetUpcomingEventsAPIRequest): GetUpcomingEventsAPIResponse {
        return this.api.get(`/resources/${resourceId}/events/upcoming`);
    }

    public getResourcesForUser(): GetResourcesForCurrentUserAPIResponse {
        return this.api.get("/resources");
    }

    public getValidTimezones(): GetValidTimezonesAPIResponse {
        return this.api.get("/resources/timezones");
    }

    public updateResource({resourceId, name, timezone}: UpdateResourceAPIRequest): UpdateResourceAPIResponse {
        return this.api.post(`/resources/${resourceId}`, {name, timezone});
    }

    public refreshTokens(): RefreshTokensAPIResponse {
        const accessToken = getToken(TokenTypes.ACCESS_TOKEN);
        const refreshToken = getToken(TokenTypes.REFRESH_TOKEN);

        if (!accessToken || !refreshToken) {
            return Promise.reject("access and/or refresh tokens not found");
        }

        const data = {
            accessToken,
            refreshToken,
        };

        deleteToken(TokenTypes.ACCESS_TOKEN);
        deleteToken(TokenTypes.REFRESH_TOKEN);

        return this.api.post("/auth/refresh", data, skipAuthRefresh);
    }

    public getCurrentUser(): GetCurrentUserAPIResponse {
        return this.api.get("/users/me");
    }

    public getUsersForResource({resourceId}: GetUsersForResourceAPIRequest): GetUsersForResourceAPIResponse {
        return this.api.get(`/resources/${resourceId}/users`);
    }

    public createUser({resourceId, email, groups, roles}: CreateUserAPIRequest): CreateUserAPIResponse {
        return this.api.post(`/resources/${resourceId}/users`, {email, groups, roles});
    }

    public activateUser(data: ActivateUserAPIRequest): ActivateUserAPIResponse {
        return this.api.post("/auth/activate", data, skipAuthRefresh);
    }

    public reactivateUser(data: ReactivateUserAPIRequest): ReactivateUserAPIResponse {
        return this.api.post("/auth/reactivate", data, skipAuthRefresh);
    }

    public updateCurrentUser(data: UpdateCurrentUserAPIRequest): UpdateCurrentUserAPIResponse {
        return this.api.post("/users/me", data);
    }

    public createAllocation({resourceId, end, start, userId}: CreateAllocationAPIRequest): CreateAllocationAPIResponse {
        return this.api.post(`/resources/${resourceId}/allocations`, {userId, start, end});
    }

    public revokeAccess({resourceId, userId}: RevokeAccessAPIRequest): RevokeAccessAPIResponse {
        return this.api.delete(`/resources/${resourceId}/users/${userId}`);
    }

    public getAuditEventsForCategory({resourceId, category}: GetAuditEventsForCategoryAPIRequest):
        GetAuditEventsForCategoryAPIResponse<typeof category> {
        return this.api.get(`/resources/${resourceId}/audit/${category}`);
    }

    public sendPasswordResetLink({email}: SendPasswordResetLinkAPIRequest): SendPasswordResetLinkAPIResponse {
        return this.api.post("/auth/forgotten-password", {email}, skipAuthRefresh);
    }

    public resetPassword(data: ResetPasswordAPIRequest): ResetPasswordAPIResponse {
        return this.api.post("/auth/reset-password", data, skipAuthRefresh);
    }

    public updateAllocation({resourceId, allocationId, userId, start, end}: UpdateAllocationAPIRequest):
        UpdateAllocationAPIResponse {
        return this.api.post(`/resources/${resourceId}/allocations/${allocationId}`, {userId, start, end});
    }

    public deleteAllocation({resourceId, allocationId}: DeleteAllocationAPIRequest): DeleteAllocationAPIResponse {
        return this.api.delete(`/resources/${resourceId}/allocations/${allocationId}`);
    }

    public updateUserRoles({resourceId, userId, roles}: UpdateUserRolesAPIRequest): UpdateUserRolesAPIResponse {
        return this.api.post(`/resources/${resourceId}/users/${userId}/roles`, {roles});
    }

    public getMyRequests({resourceId}: GetMyRequestsAPIRequest): GetMyRequestsAPIResponse {
        return this.api.get(`/resources/${resourceId}/requests`);
    }

    public getMyApprovals({resourceId}: GetMyApprovalsAPIRequest): GetMyApprovalsAPIResponse {
        return this.api.get(`/resources/${resourceId}/approvals`);
    }

    public approveAllocationRequest({resourceId, requestId, approvalId}: ApproveAllocationRequestAPIRequest):
        ApproveAllocationRequestAPIResponse {
        return this.api.post(`/resources/${resourceId}/approvals/${requestId}/approve/${approvalId}`);
    }

    public rejectAllocationRequest({resourceId, requestId, approvalId}: RejectAllocationRequestAPIRequest):
        RejectAllocationRequestAPIResponse {
        return this.api.post(`/resources/${resourceId}/approvals/${requestId}/reject/${approvalId}`);
    }

    public newAllocationRequest({resourceId, start, end}: NewAllocationRequestAPIRequest): NewAllocationRequestAPIResponse {
        return this.api.post(`/resources/${resourceId}/requests/new`, {start, end});
    }

    public releaseAllocationRequest({resourceId, allocationId}: ReleaseAllocationRequestAPIRequest): ReleaseAllocationRequestAPIResponse {
        return this.api.post(`/resources/${resourceId}/requests/release`, {allocationId});
    }

    public getPendingRequests({resourceId}: GetPendingAllocationRequestsAPIRequest): GetPendingAllocationRequestsAPIResponse {
        return this.api.get(`/resources/${resourceId}/requests/pending`);
    }

    public refreshAuthLogic(failedRequest: { response: AxiosResponse }) {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        return api.refreshTokens()
            .then((res) => {
                saveToken(TokenTypes.ACCESS_TOKEN, res.data.accessToken);
                saveToken(TokenTypes.REFRESH_TOKEN, res.data.refreshToken);
            })
            .then(() => {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,no-param-reassign
                failedRequest.response.config.headers = {...failedRequest.response.config.headers, ...authHeader()};
                // eslint-disable-next-line no-param-reassign
                failedRequest.response.config.baseURL = "/api/";

                return Promise.resolve(failedRequest);
            })
            .catch(() => {
                import("../../store/store")
                    .then((module) =>
                        module.default.dispatch(authSlice.actions.tokenRefreshFailure()))
                    .catch(() => Promise.reject(failedRequest));

                return Promise.reject(failedRequest);
            });
    }

    public shouldRetryRequest(error: AxiosError) {
        const statusCode = error.response?.status;
        let retry;

        // While this logic could be more concise, it is intentionally simple to aid understanding
        if (statusCode === undefined || error.config === undefined || error.config.method === undefined) {
            retry = true;
        } else if (["post", "put", "patch", "delete"].includes(error.config.method)) {
            retry = false;
        } else if (statusCode < 400) {
            retry = false;
        } else {
            retry = ![401, 403, 404].includes(statusCode);
        }

        return retry;
    }
}

const api = new API();

export default api;

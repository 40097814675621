import React from "react";
import {NavLink as RouterNavLink} from "react-router-dom";
import {
    NavItem as BootstrapNavItem,
    NavLink,
} from "reactstrap";

import {useAppDispatch} from "../../store/hooks";
import navigationSlice from "./state";

interface INavItemProps {
    to?: string;
    text: string;
    type?: NavItemTypes;
    end?: boolean;
    dataTestKey?: string;
}

export enum NavItemTypes {
    link,
    static,
}

const NavItem: React.FC<INavItemProps> = ({to = "", text, type = NavItemTypes.link, end = false, dataTestKey}) => {
    const dispatch = useAppDispatch();
    
    const onNavLinkClick = () => {
        dispatch(navigationSlice.actions.toggleMenuOff());
    }

    const dataTest = dataTestKey? `nav-for-${dataTestKey}` : `nav-for-${text.replace(" ", "_")}`;

    return (
        <BootstrapNavItem>
            {type === NavItemTypes.link ?
                <NavLink 
                tag={RouterNavLink} 
                to={to} 
                className="main-menu-link" 
                end={end}
                data-test={dataTest} 
                onClick={onNavLinkClick}>
                    {text}
                </NavLink>
                : <NavLink className="main-menu-link">{text}</NavLink>}
        </BootstrapNavItem>
    );
};
export default NavItem;

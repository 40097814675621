const ADMIN_ROLE = {value: "admin", display: "Administrator"};
const OWNER_ROLE = {value: "owner", display: "Owner"};
const NO_RESOURCES_AVAILABLE_ERROR = "You do not have access to any resources";
const DATETIME_FORMAT = "dd.LL.yyyy HH:mm:ss";
const DATE_FORMAT = "dd.LL.yyyy";
const NONEXISTENT_EVENT_ID = -999;
const YEAR_RANGE_MODIFIER = 10;

export {
    ADMIN_ROLE,
    OWNER_ROLE,
    NO_RESOURCES_AVAILABLE_ERROR,
    DATETIME_FORMAT,
    DATE_FORMAT,
    NONEXISTENT_EVENT_ID,
    YEAR_RANGE_MODIFIER,
};

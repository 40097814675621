import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {AxiosError} from "axios";

import {GetAuditEventsForCategoryAPIRequest} from "../api/types";
import api from "../api/api";
import {AuditCategories, IAuditEvent} from "./models";
import resourcesSlice from "../resources/state";
import authSlice, {logout} from "../auth/state/state";

interface IAuditState {
    readonly events: IAuditEvent<AuditCategories>[];
}

const initialState = {
    events: [] as IAuditEvent<AuditCategories.Allocations>[]
} as IAuditState;

const getEventsForCategory = createAsyncThunk<
    IAuditEvent<AuditCategories>[] | undefined,
    GetAuditEventsForCategoryAPIRequest,
    {rejectValue: AxiosError}
>(
    'audit/getEventsForCategory',
    async (data, {rejectWithValue}) => {
        try {
            const response = await api.getAuditEventsForCategory(data);
            return response.data.events;
        } catch (err) {
            return rejectWithValue(err as AxiosError);
        }
    }
);

const auditSlice = createSlice({
    name: "audit",
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
        .addCase(getEventsForCategory.fulfilled, (state, action) => {
            if (action.payload) {
                state.events = action.payload;
            }
        })
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .addCase(resourcesSlice.actions.selectResource, (state, action) => {
            return initialState;
        })
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .addCase(authSlice.actions.tokenRefreshFailure, (state, action) => {
            return initialState;
        })
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .addCase(logout.fulfilled, (state, action) => {
            return initialState;
        })
    }
});

export default auditSlice;

export {
    getEventsForCategory,
    initialState
};

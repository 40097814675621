const DASHBOARD_ROUTE = "/";
const LOGIN_ROUTE = "/login";
const LOGOUT_ROUTE = "/logout";
const PROFILE_ROUTE = "/profile";
const MANAGE_ROUTE = "/manage";
const ALLOCATIONS_ROUTE = "/allocations";
const USER_ACTIVATION_ROUTE = "/activate";
const USER_REACTIVATION_ROUTE = "/reactivate";
const AUDIT_ROUTE = "/audit";
const PASSWORD_RESET_ROUTE = "/reset-password";
const MY_REQUESTS_ROUTE = "/requests";
const MY_APPROVALS_ROUTE = "/approvals";

export {
    DASHBOARD_ROUTE,
    LOGIN_ROUTE,
    LOGOUT_ROUTE,
    PROFILE_ROUTE,
    MANAGE_ROUTE,
    ALLOCATIONS_ROUTE,
    USER_ACTIVATION_ROUTE,
    USER_REACTIVATION_ROUTE,
    AUDIT_ROUTE,
    PASSWORD_RESET_ROUTE,
    MY_REQUESTS_ROUTE,
    MY_APPROVALS_ROUTE,
};

import {Color} from "chroma-js";
import {ObjectType} from "./types";

interface IAxiosErrorWithDetail {
    response?: {
        data?: {
            detail: string | Array<{msg?: string}>;
        }
    }
}

const extractServerError = (error: IAxiosErrorWithDetail, defaultMessage?: string): string => {
    if (error && error.response && error.response.data) {
        if (error.response.data.detail) {
            const detail = error.response.data.detail;

            if (typeof detail === "string") {
                return detail;
            } else if (Array.isArray(detail)) {
                const errors = [];

                for (const err of detail) {
                    errors.push(err?.msg || "Internal server error");
                }

                return errors.join("; ");
            }
        }
    }

    return defaultMessage || "Internal server error, please try again later";
};

// Based on the accepted answer for https://stackoverflow.com/questions/3942878/how-to-decide-font-color-in-white-
// or-black-depending-on-background-color
const getTextColourForBackground = (background: Color): string => {
    const [red, green, blue] = background.rgb();
    return (red * 0.299 + green * 0.587 + blue * 0.114) > 186 ? "black" : "white";
};

const deleteById = <T extends ObjectType>(objects: T[], objectId: number): T[] => {
    const updated = objects;
    const index = objects.findIndex((elem) => elem.id === objectId);
    if (index !== -1) {
        updated.splice(index, 1);
    }

    return updated;
};

const findAndUpdate = <T extends ObjectType>(objects: T[], updatedObject: T): T[] => {
    const updated = objects;
    const index = objects.findIndex((elem) => elem.id === updatedObject.id);
    if (index !== -1) {
        updated[index] = updatedObject;
    }

    return updated;
};

export {
    extractServerError,
    getTextColourForBackground,
    deleteById,
    findAndUpdate
};

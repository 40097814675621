import jwtDecode from "jwt-decode";
import {IResource} from "../../resources/types";

export enum TokenTypes {
    ACCESS_TOKEN = "accessToken",
    REFRESH_TOKEN = "refreshToken",
}

export type Scopes = Record<number, string[]>;
export type RequiredRoles = Array<string[]> | string[];

interface ITokenData {
    user: number;
    session: number;
    scopes: Scopes;
}

class TokenData {
    public readonly userId: number;
    public readonly sessionId: number;
    public readonly scopes: Scopes;

    constructor(token: ITokenData) {
        this.userId = token.user;
        this.sessionId = token.session;
        this.scopes = token.scopes;
    }
}

const saveToken = (type: TokenTypes, token: string): void => {
    console.log(`Saving ${type}`);
    localStorage.setItem(type, token);
};

const getToken = (type: TokenTypes): string | null => {
    return localStorage.getItem(type);
};

const deleteToken = (type: TokenTypes): void => {
    localStorage.removeItem(type);
};

const parseToken = (token: string): TokenData => {
    const decoded = jwtDecode<ITokenData>(token);

    return new TokenData(decoded);
};

const hasValidToken = (): boolean => {
    try {
        const token = getToken(TokenTypes.ACCESS_TOKEN) || "";
        parseToken(token);
        console.info("Found a valid auth token");
        return true;
    } catch (e) {
        console.error("No valid auth token found");
        return false;
    }
};

const getUserRoles = (): Record<number, string[]> => {
    const token = getToken(TokenTypes.ACCESS_TOKEN);

    try {
        return token ? parseToken(token).scopes : {};
    } catch (e) {
        return {};
    }
};

const isAuthorised = (requiredRoles: RequiredRoles | undefined, selectedResource: IResource | undefined, scopes: Scopes): boolean => {
    if (requiredRoles === undefined || selectedResource === undefined) {
        return true;
    }

    if (selectedResource.id in scopes) {
        const scopesForResource = scopes[selectedResource.id];

        for (const requiredRole of requiredRoles) {
            if (Array.isArray(requiredRole)) {
                if (!requiredRole.some((role) => scopesForResource.includes(role))) {
                    return false;
                }
            } else {
                if (!scopesForResource.includes(requiredRole)) {
                    return false;
                }
            }
        }

        return true;
    }

    return false;
}

export {
    TokenData,
    saveToken,
    getToken,
    deleteToken,
    parseToken,
    hasValidToken,
    getUserRoles,
    isAuthorised,
};

import {configureStore} from '@reduxjs/toolkit'

import initialState from "./initialState";
import {rootReducer} from "./rootReducer";

const store = configureStore({
    reducer: rootReducer,
    preloadedState: initialState,
    devTools: true
  })

export type AppState = ReturnType<typeof rootReducer>;

export default store;

import {initialState as allocationsState} from "../app/allocations/state";
import {initialState as authState} from "../app/auth/state/state";
import {initialState as navigationState} from "../app/navigation/state";
import {initialState as resourcesState} from "../app/resources/state";
import {initialState as usersState} from "../app/users/state/state";
import {initialState as auditState} from "../app/audit/state";
import {initialState as approvalsState} from "../app/approvals/state";
import {AppState} from "./store";

const initialState: AppState = {
    allocations: allocationsState,
    approvals: approvalsState,
    auth: authState,
    navigation: navigationState,
    resources: resourcesState,
    users: usersState,
    audit: auditState,
} as AppState;

export default initialState;

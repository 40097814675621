import classNames from "classnames";
import React from "react";
import {Container} from "reactstrap";

interface IComponentErrorProps {
    text: string;
}

const ComponentError: React.FC<IComponentErrorProps> = ({text}) => {
    return (
        <div className="vertical-center">
            <Container fluid>
                <p className={classNames("error", "text-center")} data-test="component-error">
                    {text}
                </p>
            </Container>
        </div>
    );
};

export default ComponentError;

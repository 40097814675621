import React from "react";
import {connect} from "react-redux";
import {Outlet, Navigate, useNavigationType, useLocation} from "react-router-dom";

import {AppState} from "../../store/store";
import {DASHBOARD_ROUTE, LOGIN_ROUTE} from "../navigation/routes";
import {getUserRoles, isAuthorised, RequiredRoles} from "./state/tokenStorage";
import {IResource} from "../resources/types";
import Page from "../common/Page";

interface IRequireAuthProps {
    authenticated: boolean;
    selectedResource?: IResource;
    requiredRoles?: RequiredRoles;
}

const RequireAuth: React.FC<IRequireAuthProps> = ({authenticated, selectedResource, requiredRoles}) => {
    const scopes = getUserRoles();
    const navigationType = useNavigationType();
    const location = useLocation();

    const redirector = <Navigate to={LOGIN_ROUTE} replace state={{from: location.pathname}}/>;
    let keyProp: Record<string, string> | {key: number};

    // Only force-refresh components when the user navigates to them from the menu
    if (navigationType === "PUSH") {
        keyProp = {key: Math.random()};
    } else {
        keyProp = {};
    }

    if (authenticated) {
        if (isAuthorised(requiredRoles, selectedResource, scopes)) {
            return (<Page keyProp={keyProp}>
                <Outlet />
            </Page>);
        } else if (location.pathname !== DASHBOARD_ROUTE) {
            return <Navigate to={DASHBOARD_ROUTE} replace state={{from: location.pathname}}/>;
        } else {
            return <Navigate to={LOGIN_ROUTE} replace state={{from: location.pathname}}/>;
        }
    } else {
        return redirector;
    }
};

const mapStateToProps = (state: AppState) => ({
    authenticated: state.auth.authenticated,
    selectedResource: state.resources.selectedResource,
});

export default connect(mapStateToProps)(RequireAuth);

import React from "react";

import NavMenuContainer from "../navigation/NavMenuContainer";
import ErrorBoundary from "./errors/ErrorBoundary";
import Container from "../common/Container";

interface IPageProps {
    children: React.ReactNode;
    keyProp?: Record<string, string> | {key: number};
}

class Page extends React.PureComponent<IPageProps> {
    public render() {
        return (
            <React.Fragment>
                <NavMenuContainer/>
                <ErrorBoundary {...this.props.keyProp}>
                    <Container>
                        {this.props.children}
                    </Container>
                </ErrorBoundary>
            </React.Fragment>
        );
    }
}

export default Page;

import React from "react";
import {DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown} from "reactstrap";

import {IResource} from "../resources/types";
import NavItem, {NavItemTypes} from "./NavItem";

interface IResourceSelectorProps {
    selectedResource: IResource;
    resources: IResource[];
    handleResourceSelection: (id: number) => void;
}

const ResourceSelector: React.FC<IResourceSelectorProps> = ({selectedResource, resources, handleResourceSelection}) => {
    return (
        resources.length > 1 ?
            <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret className="main-menu-link">
                    {selectedResource.name}
                </DropdownToggle>
                <DropdownMenu>
                    <DropdownItem active className="main-menu-link">
                        {selectedResource.name}
                    </DropdownItem>

                    {resources.map((resource) => {
                        if (resource.id !== selectedResource.id) {
                            return <DropdownItem key={resource.id} className="main-menu-link"
                                                 onClick={(event) => {
                                                     event.preventDefault();
                                                     handleResourceSelection(resource.id);
                                                 }}>
                                {resource.name}
                            </DropdownItem>;
                        } else {
                            return null;
                        }
                    })}
                </DropdownMenu>
            </UncontrolledDropdown>
            : <NavItem text={selectedResource.name} type={NavItemTypes.static} />
    );
};

export default ResourceSelector;

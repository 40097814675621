import React from "react";
import {Collapse, Nav, Navbar, NavbarToggler,} from "reactstrap";
import classNames from "classnames";

import {ADMIN_ROLE, OWNER_ROLE} from "../common/constants";
import {IResource} from "../resources/types";
import ResourceSelector from "./ResourceSelector";
import {Scopes} from "../auth/state/tokenStorage";
import NavItem, {NavItemTypes} from "./NavItem";
import * as routes from "../navigation/routes";
import FormSpinner from "../common/library/Spinner/FormSpinner";

import "./navigation.scss";

interface INavMenuProps {
    resources: IResource[];
    selectedResource?: IResource;
    handleResourceSelection: (id: number) => void;
    userRoles: Scopes;
    toggleMenuOn: () => void;
    toggleMenuOff: () => void;
    menuToggled: boolean;
    resourcesLoading: boolean;
    error?: string;
}

const NavMenu: React.FC<INavMenuProps> = ({
                                              resources, selectedResource, handleResourceSelection,
                                              userRoles, menuToggled, toggleMenuOn, toggleMenuOff,
                                              resourcesLoading, error
                                          }) => {
    const getNavItemsForResource = () => {
        if (error) {
            return <NavItem type={NavItemTypes.static} text={error}/>;
        }

        const noAccessError = <NavItem type={NavItemTypes.static}
                                       text={`You do not have access to ${selectedResource ? "the selected resource" : "any resources"}`}/>;
        const rolesForSelectedResource = selectedResource ? userRoles[selectedResource.id] : undefined;
        if (!rolesForSelectedResource) {
            return noAccessError;
        }

        const hasSomeRole = rolesForSelectedResource.includes(ADMIN_ROLE.value) || rolesForSelectedResource.includes(OWNER_ROLE.value);
        if (!hasSomeRole) {
            return noAccessError;
        }

        return <React.Fragment>
            <NavItem to={routes.DASHBOARD_ROUTE} text="Dashboard" end={true}/>
            {rolesForSelectedResource.includes(ADMIN_ROLE.value) && <React.Fragment>
                <NavItem to={routes.ALLOCATIONS_ROUTE} text="Allocations" />
                <NavItem to={routes.MANAGE_ROUTE} text="Manage" />
                <NavItem to={routes.AUDIT_ROUTE} text="Audit"/>
            </React.Fragment>}
            {rolesForSelectedResource.includes(OWNER_ROLE.value) && <React.Fragment>
                <NavItem to={routes.MY_REQUESTS_ROUTE} text="Requests" />
            </React.Fragment>}
            <NavItem to={routes.MY_APPROVALS_ROUTE} text="Approvals" />
        </React.Fragment>;
    }

    const toggleMenu = () => {
        if (menuToggled) {
            toggleMenuOff();
        } else {
            toggleMenuOn();
        }
    }

    return (
        <Navbar light expand="md" className="main-menu" data-test="main-menu">
            <NavbarToggler onClick={toggleMenu}/>
            {resourcesLoading ?
                <div className={classNames("mx-auto", "order-0")}>
                    <div className="mx-auto">
                        <FormSpinner colour="light"/>
                    </div>
                </div>
                : <React.Fragment>
                    <Collapse isOpen={menuToggled} navbar className={classNames("w-100", "order-1 order-md-0")}>
                        <Nav navbar id="main-menu-left1" className={classNames("me-auto", "ms-1")}>
                            {resources.length !== 0 && selectedResource &&
                            <ResourceSelector selectedResource={selectedResource} resources={resources}
                                              handleResourceSelection={handleResourceSelection}/>}
                            {getNavItemsForResource()}
                        </Nav>
                    </Collapse>
                    <Collapse isOpen={menuToggled} navbar className={classNames("w-100", "order-3")}>
                        <Nav navbar id="main-menu-right1" className={classNames("ms-auto", "me-1")}>
                            <NavItem to={routes.PROFILE_ROUTE} text="Profile"
                                     dataTestKey="Profile"/>
                            <NavItem to={routes.LOGOUT_ROUTE} text="Logout"/>
                        </Nav>
                    </Collapse>
                </React.Fragment>}
        </Navbar>
    );
};

export default NavMenu;

import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosError } from "axios";

import api from "../api/api";
import {IResource} from "./types";
import authSlice, {logout, login} from "../auth/state/state";
import { UpdateResourceAPIRequest } from "../api/types";

interface IResourcesState {
    readonly resources: IResource[];
    readonly selectedResource?: IResource;
    readonly noResourcesAvailable: boolean;
    readonly failedToLoad: boolean;
    readonly validTimezones: string[];
}

const initialState = {
    resources: [],
    selectedResource: undefined,
    noResourcesAvailable: false,
    failedToLoad: false,
    validTimezones: []
} as IResourcesState;

const getResourcesForCurrentUser = createAsyncThunk<IResource[] | undefined, void, {rejectValue: AxiosError}>(
    'resources/getResourcesForCurrentUser',
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async (data, {rejectWithValue}) => {
        try {
            const response = await api.getResourcesForUser();        

            return response.data.resources;
        } catch (err) {
            return rejectWithValue(err as AxiosError);
        }
    }
);

const getValidTimezones = createAsyncThunk<string[] | undefined, void, {rejectValue: AxiosError}>(
    'resources/getValidTimezones',
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async (data, {rejectWithValue}) => {
        try {
            const response = await api.getValidTimezones();        

            return response.data.timezones;
        } catch (err) {
            return rejectWithValue(err as AxiosError);
        }
    }
);

const updateResource = createAsyncThunk<IResource | undefined, UpdateResourceAPIRequest, {rejectValue: AxiosError}>(
    'resources/updateResource',
    async (data, {rejectWithValue}) => {
        try {
            const response = await api.updateResource(data);        

            return response.data;
        } catch (err) {
            return rejectWithValue(err as AxiosError);
        }
    }
);

const resourcesSlice = createSlice({
    name: "resources",
    initialState,
    reducers: {
        selectDefaultResource: (state) => {
            state.selectedResource = state.resources.length !== 0 ? state.resources[0] : undefined;
        },
        selectResource: (state, action: PayloadAction<IResource>) => {
            state.selectedResource = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .addCase(getResourcesForCurrentUser.pending, (state, action) => {
            return initialState;
        })
        .addCase(getResourcesForCurrentUser.fulfilled, (state, action) => {
            if (action.payload) {
                state.resources = action.payload;
                state.noResourcesAvailable = action.payload.length === 0;
                state.failedToLoad = false;

                if (state.selectedResource === undefined) {
                    state.selectedResource = action.payload.length !== 0 ? action.payload[0] : undefined;
                }
            }
        })
        .addCase(getResourcesForCurrentUser.rejected, (state, action) => {
            if (action.payload) {
                const noResourcesAvailable = [401, 403].includes(action.payload.response?.status || 0);

                state.resources = [];
                state.selectedResource = undefined;
                state.noResourcesAvailable = noResourcesAvailable;
                state.failedToLoad = !noResourcesAvailable;
            }
        })
        .addCase(getValidTimezones.fulfilled, (state, action) => {
            if (action.payload) {
                state.validTimezones = action.payload;
            }
        })
        .addCase(updateResource.fulfilled, (state, action) => {
            if (action.payload) {
                state.selectedResource = action.payload;
                state.resources = state.resources.map(
                    (resource) => (action.payload && resource.id === action.payload.id) ?
                        action.payload : resource
                );
            }
        })
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .addCase(authSlice.actions.tokenRefreshFailure, (state, action) => {
            return initialState;
        })
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .addCase(logout.fulfilled, (state, action) => {
            return initialState;
        })

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .addCase(login.fulfilled, (state, action) => {
            state.noResourcesAvailable = false;
            state.failedToLoad = false;
        })
    }
});

export default resourcesSlice;

export {
    getResourcesForCurrentUser,
    getValidTimezones,
    updateResource,
    initialState
};
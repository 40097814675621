import React from "react";
import {Col, Row, Spinner as BootstrapSpinner} from "reactstrap";
import classNames from "classnames";

interface IFormSpinnerProps {
    colour?: string;
}

const FormSpinner: React.FC<IFormSpinnerProps> = ({colour = "primary"}) => {
    return (
        <Row className={classNames("justify-content-center", "mt-2")} data-test="form-spinner">
            <Col md="auto" className="text-center">
                <BootstrapSpinner size="xl" color={colour} />
            </Col>
        </Row>
    );
};

export default FormSpinner;

import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {AxiosError} from "axios";

import {Approval, IAllocationRequest} from "./types";
import { 
    ApproveAllocationRequestAPIRequest,
    GetMyApprovalsAPIRequest,
    GetMyRequestsAPIRequest,
    NewAllocationRequestAPIRequest,
    RejectAllocationRequestAPIRequest,
    ReleaseAllocationRequestAPIRequest
} from "../api/types";
import api from "../api/api";
import allocationsSlice from "../allocations/state";
import User from "../users/state/User";
import {AppState} from "../../store/store";
import resourcesSlice from "../resources/state";
import authSlice, {logout} from "../auth/state/state";

interface IApprovalsState {
    readonly myRequests: IAllocationRequest[];
    readonly myApprovals: IAllocationRequest[];
    readonly requiredApprovers: Approval[];
}

const initialState = {
    myRequests: [] as IAllocationRequest[],
    myApprovals: [] as IAllocationRequest[],
    requiredApprovers: [] as Approval[],
} as IApprovalsState;

const getMyRequests = createAsyncThunk<IAllocationRequest[] | undefined, GetMyRequestsAPIRequest, {rejectValue: AxiosError}>(
    'approvals/getMyRequests',
    async (data, {rejectWithValue}) => {
        try {
            const response = await api.getMyRequests(data);
            return response.data.requests;
        } catch (err) {
            return rejectWithValue(err as AxiosError);
        }
    }
);

const getMyApprovals = createAsyncThunk<IAllocationRequest[] | undefined, GetMyApprovalsAPIRequest, {rejectValue: AxiosError}>(
    'approvals/getMyApprovals',
    async (data, {rejectWithValue}) => {
        try {
            const response = await api.getMyApprovals(data);
            return response.data.approvals;
        } catch (err) {
            return rejectWithValue(err as AxiosError);
        }
    }
);

const approveAllocationRequest = createAsyncThunk<string | undefined, ApproveAllocationRequestAPIRequest, {rejectValue: AxiosError}>(
    'approvals/approveAllocationRequest',
    async (data, {rejectWithValue}) => {
        try {
            const response = await api.approveAllocationRequest(data);
            return response.data.detail;
        } catch (err) {
            return rejectWithValue(err as AxiosError);
        }
    }
);

const rejectAllocationRequest = createAsyncThunk<string | undefined, RejectAllocationRequestAPIRequest, {rejectValue: AxiosError}>(
    'approvals/rejectAllocationRequest',
    async (data, {rejectWithValue}) => {
        try {
            const response = await api.rejectAllocationRequest(data);
            return response.data.detail;
        } catch (err) {
            return rejectWithValue(err as AxiosError);
        }
    }
);

const newAllocationRequest = createAsyncThunk<
    Approval[] | undefined,
    NewAllocationRequestAPIRequest,
    {
        rejectValue: AxiosError,
        state: AppState
    }
>(
    'approvals/newAllocationRequest',
    async (data, {rejectWithValue, dispatch, getState}) => {
        try {
            const response = await api.newAllocationRequest(data);

            // Get the current user's details
            const currentUser: User = getState().users.currentUser || new User({
                id: 0,
                name: "SYSTEM",
                email: "noreply@nobisplan.com",
                active: true,
                roles: []
            });

            const isAutoApproved = response.data.requiredApprovers.length === 0;
            dispatch(allocationsSlice.actions.addPendingEvent({
                autoApproved: isAutoApproved,
                event: {
                    id: response.data.allocationId,
                    start: response.data.start,
                    end: response.data.end,
                    title: currentUser.name,
                    user: currentUser,
                    pending: !isAutoApproved,
                }
            }));

            return response.data.requiredApprovers;
        } catch (err) {
            return rejectWithValue(err as AxiosError);
        }
    }
);

const releaseAllocationRequest = createAsyncThunk<
    Approval[] | undefined,
    ReleaseAllocationRequestAPIRequest,
    {
        rejectValue: AxiosError
    }
>(
    'approvals/releaseAllocationRequest',
    async (data, {rejectWithValue, dispatch}) => {
        try {
            const response = await api.releaseAllocationRequest(data);

            dispatch(allocationsSlice.actions.removeReleasedAllocation({
                allocationId: response.data.allocationId,
                autoApproved: response.data.requiredApprovers.length === 0
            }));

            return response.data.requiredApprovers;
        } catch (err) {
            return rejectWithValue(err as AxiosError);
        }
    }
);

const approvalsSlice = createSlice({
    name: "approvals",
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
        .addCase(getMyRequests.fulfilled, (state, action) => {
            if (action.payload) {
                state.myRequests = action.payload;
            }
        })
        .addCase(getMyApprovals.fulfilled, (state, action) => {
            if (action.payload) {
                state.myApprovals = action.payload;
            }
        })
        .addCase(newAllocationRequest.fulfilled, (state, action) => {
            if (action.payload) {
                state.requiredApprovers = action.payload;
            }
        })
        .addCase(releaseAllocationRequest.fulfilled, (state, action) => {
            if (action.payload) {
                state.requiredApprovers = action.payload
            }
        })
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .addCase(resourcesSlice.actions.selectResource, (state, action) => {
            return initialState;
        })
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .addCase(authSlice.actions.tokenRefreshFailure, (state, action) => {
            return initialState;
        })
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .addCase(logout.fulfilled, (state, action) => {
            return initialState;
        })
    }
});

export default approvalsSlice;

export {
    getMyRequests,
    getMyApprovals,
    approveAllocationRequest,
    rejectAllocationRequest,
    newAllocationRequest,
    releaseAllocationRequest,
    initialState
};

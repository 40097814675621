import {combineReducers} from "@reduxjs/toolkit";

import allocationsSlice from "../app/allocations/state";
import authSlice from "../app/auth/state/state";
import navigationSlice from "../app/navigation/state";
import resourcesSlice from "../app/resources/state";
import usersSlice from "../app/users/state/state";
import auditSlice from "../app/audit/state";
import approvalsSlice from "../app/approvals/state";

const rootReducer = combineReducers({
    allocations: allocationsSlice.reducer,
    approvals: approvalsSlice.reducer,
    auth: authSlice.reducer,
    navigation: navigationSlice.reducer,
    resources: resourcesSlice.reducer,
    users: usersSlice.reducer,
    audit: auditSlice.reducer,
});

export {
    rootReducer
};

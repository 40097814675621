import {connect} from "react-redux";
import React from "react";

import {AppState} from "../../store/store";
import {NO_RESOURCES_AVAILABLE_ERROR} from "./constants";
import ComponentError from "./errors/ComponentError";

interface IContainerProps {
    noResourcesAvailable: boolean;
    failedToLoad: boolean;
    children: React.ReactNode;
}

class Container extends React.PureComponent<IContainerProps> {
    public render() {
        if (this.props.noResourcesAvailable) {
            return <ComponentError text={NO_RESOURCES_AVAILABLE_ERROR}/>;
        } else if (this.props.failedToLoad) {
            return <ComponentError text="Failed to load your resources, please try again later"/>;
        }

        return this.props.children;
    }
}

const mapStateToProps = (state: AppState) => ({
    noResourcesAvailable: state.resources.noResourcesAvailable,
    failedToLoad: state.resources.failedToLoad,
});

const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Container);

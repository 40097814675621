import React, {lazy, Suspense} from "react";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";

import RequireAuth from "./auth/RequireAuth";
import {ADMIN_ROLE, OWNER_ROLE} from "./common/constants";
import ErrorBoundary from "./common/errors/ErrorBoundary";
import * as routes from "./navigation/routes";
import Spinner from "./common/library/Spinner/Spinner";

const Allocations = lazy(() => import("./allocations/Allocations"));
const UserActivation = lazy(() => import("./auth/activate/UserActivation"));
const Login = lazy(() => import("./auth/Login"));
const Logout = lazy(() => import("./auth/Logout"));
const Dashboard = lazy(() => import("./dashboard/Dashboard"));
const ManageContainer = lazy(() => import("./users/management/ManageContainer"));
const ProfileContainer = lazy(() => import("./users/profile/Profile"));
const UserReactivation = lazy(() => import("./auth/reactivate/UserReactivation"));
const Audit = lazy(() => import("./audit/Audit"));
const PasswordReset = lazy(() => import("./auth/passwordReset/PasswordReset"));
const MyRequests = lazy(() => import("./approvals/MyRequests/MyRequests"));
const MyApprovals = lazy(() => import("./approvals/MyApprovals/MyApprovals"));
const Page = lazy(() => import("./common/Page"));
const NotFound = lazy(() => import("./navigation/NotFound"));

const App: React.FC = () => {
    return (
        <Router>
            <Suspense fallback={<Spinner/>}>
                <Routes>
                    <Route path={routes.LOGIN_ROUTE} element={
                        <ErrorBoundary>
                            <Login/>
                        </ErrorBoundary>
                    }/>
                    <Route path={routes.USER_ACTIVATION_ROUTE} element={
                        <ErrorBoundary>
                            <UserActivation/>
                        </ErrorBoundary>
                    }/>
                    <Route path={routes.USER_REACTIVATION_ROUTE} element={
                        <ErrorBoundary>
                            <UserReactivation/>
                        </ErrorBoundary>
                    }/>
                    <Route path={routes.PASSWORD_RESET_ROUTE} element={
                        <ErrorBoundary>
                            <PasswordReset/>
                        </ErrorBoundary>
                    }/>
                    <Route element={<RequireAuth requiredRoles={[[ADMIN_ROLE.value, OWNER_ROLE.value]]} />}>
                        <Route path={routes.DASHBOARD_ROUTE} element={<Dashboard/>} />
                    </Route>
                    <Route element={<RequireAuth />}>
                        <Route path={routes.LOGOUT_ROUTE} element={<Logout/>} />
                    </Route>
                    <Route element={<RequireAuth requiredRoles={[ADMIN_ROLE.value]} />}>
                        <Route path={routes.MANAGE_ROUTE} element={<ManageContainer/>} />
                    </Route>
                    <Route element={<RequireAuth requiredRoles={[[ADMIN_ROLE.value, OWNER_ROLE.value]]} />}>
                        <Route path={routes.PROFILE_ROUTE} element={<ProfileContainer />}/>
                    </Route>
                    <Route element={<RequireAuth requiredRoles={[ADMIN_ROLE.value]} />}>
                        <Route path={routes.ALLOCATIONS_ROUTE} element={<Allocations />}/>
                    </Route>
                    <Route element={<RequireAuth requiredRoles={[ADMIN_ROLE.value]} />}>
                        <Route path={routes.AUDIT_ROUTE} element={<Audit />}/>
                    </Route>
                    <Route element={<RequireAuth requiredRoles={[OWNER_ROLE.value]} />}>
                        <Route path={routes.MY_REQUESTS_ROUTE} element={<MyRequests />}/>
                    </Route>
                    <Route element={<RequireAuth requiredRoles={[[OWNER_ROLE.value, ADMIN_ROLE.value]]}/>}>
                        <Route path={routes.MY_APPROVALS_ROUTE} element={<MyApprovals />}/>
                    </Route>
                    <Route path="*" element={
                        <Page>
                            <NotFound/>
                        </Page>
                    }/>
                </Routes>
            </Suspense>
        </Router>
    );
};

export default App;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosError } from "axios";

import api from "../../api/api";
import { getUserRoles } from "../../auth/state/tokenStorage";
import User from "./User";
import resourcesSlice from "../../resources/state";
import {
    CreateUserAPIRequest,
    GetUsersForResourceAPIRequest,
    RevokeAccessAPIRequest,
    UpdateCurrentUserAPIRequest,
    UpdateUserRolesAPIRequest
} from "../../api/types";
import {deleteById} from "../../common/helpers";
import authSlice, {logout} from "../../auth/state/state";

interface IUsersState {
    readonly currentUser?: User;
    readonly users: User[];
}

const initialState = {
    currentUser: undefined,
    users: [] as User[],
} as IUsersState;

const getCurrentUser = createAsyncThunk<User | undefined, void, {rejectValue: AxiosError}>(
    'users/getCurrentUser',
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async (data, {rejectWithValue}) => {
        try {
            const response = await api.getCurrentUser();

            return {...response.data, roles: getUserRoles()};
        } catch (err) {
            return rejectWithValue(err as AxiosError);
        }
    }
);

const updateCurrentUser = createAsyncThunk<User | undefined, UpdateCurrentUserAPIRequest, {rejectValue: AxiosError}>(
    'users/updateCurrentUser',
    async (data, {rejectWithValue}) => {
        try {
            const response = await api.updateCurrentUser(data);

            return response.data;
        } catch (err) {
            return rejectWithValue(err as AxiosError);
        }
    }
);

const getUsersForResource = createAsyncThunk<User[] | undefined, GetUsersForResourceAPIRequest, {rejectValue: AxiosError}>(
    'users/getUsersForResource',
    async (data, {rejectWithValue}) => {
        try {
            const response = await api.getUsersForResource(data);

            return response.data.users;
        } catch (err) {
            return rejectWithValue(err as AxiosError);
        }
    }
);

const revokeAccess = createAsyncThunk<number | undefined, RevokeAccessAPIRequest, {rejectValue: AxiosError}>(
    'users/revokeAccess',
    async (data, {rejectWithValue}) => {
        try {
            await api.revokeAccess(data);

            return data.userId;
        } catch (err) {
            return rejectWithValue(err as AxiosError);
        }
    }
);

const createUser = createAsyncThunk<User | undefined, CreateUserAPIRequest, {rejectValue: AxiosError}>(
    'users/createUser',
    async (data, {rejectWithValue}) => {
        try {
            const response = await api.createUser(data);

            return response.data;
        } catch (err) {
            return rejectWithValue(err as AxiosError);
        }
    }
);

const updateUserRoles = createAsyncThunk<UpdateUserRolesAPIRequest | undefined, UpdateUserRolesAPIRequest, {rejectValue: AxiosError}>(
    'users/updateUserRoles',
    async (data, {rejectWithValue}) => {
        try {
            await api.updateUserRoles(data);

            return data;
        } catch (err) {
            return rejectWithValue(err as AxiosError);
        }
    }
);

const usersSlice = createSlice({
    name: "users",
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
        .addCase(getCurrentUser.fulfilled, (state, action) => {
            if (action.payload) {
                state.currentUser = action.payload;
            }
        })
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .addCase(getCurrentUser.rejected, (state, action) => {
            state.currentUser = undefined;
        })
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .addCase(resourcesSlice.actions.selectResource, (state, action) => {
            return initialState;
        })
        .addCase(updateCurrentUser.fulfilled, (state, action) => {
            if (action.payload) {
                state.currentUser = action.payload;
            }
        })
        .addCase(getUsersForResource.fulfilled, (state, action) => {
            if (action.payload) {
                state.users = action.payload;
            }
        })
        .addCase(revokeAccess.fulfilled, (state, action) => {
            if (action.payload) [
                state.users = deleteById(state.users, action.payload)
            ]
        })
        .addCase(createUser.fulfilled, (state, action) => {
            if (action.payload) {
                state.users.push(action.payload);
            }
        })
        .addCase(updateUserRoles.fulfilled, (state, action) => {
            if (action.payload) {
                const index = state.users.findIndex((user) => user.id == action.payload?.userId);
                if (index !== -1 && state.users[index] && state.users[index].roles) {
                    state.users[index].roles[action.payload.resourceId] = action.payload.roles;
                }
            }
        })
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .addCase(authSlice.actions.tokenRefreshFailure, (state, action) => {
            return initialState;
        })
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .addCase(logout.fulfilled, (state, action) => {
            return initialState;
        })
    }
});

export default usersSlice;

export {
    getCurrentUser,
    updateCurrentUser,
    getUsersForResource,
    revokeAccess,
    createUser,
    updateUserRoles,
    initialState
};

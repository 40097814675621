interface IUserConstructor {
    id: number;
    name: string;
    email: string;
    active: boolean;
    roles: Record<number, string[]>;
}

class User {
    public readonly id: number;
    public readonly email: string;
    public readonly name: string;
    public readonly active: boolean;
    public readonly roles: Record<number, string[]>;

    constructor({id, name, email, active, roles}: IUserConstructor) {
        this.id = id;
        this.email = email;
        this.name = name;
        this.active = active;
        this.roles = roles;
    }
}

export default User;

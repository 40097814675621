import {createSlice, PayloadAction, createAsyncThunk} from "@reduxjs/toolkit";
import {AxiosError} from "axios";

import IEvent from "../dashboard/calendar/Event";
import {findAndUpdate} from "../common/helpers";
import {
    GetEventsForYearAPIRequest,
    GetUpcomingEventsAPIRequest,
    CreateAllocationAPIRequest,
    UpdateAllocationAPIRequest,
    DeleteAllocationAPIRequest,
    GetPendingAllocationRequestsAPIRequest
} from "../api/types";
import api from "../api/api";
import resourcesSlice from "../resources/state";
import authSlice, {logout} from "../auth/state/state";

interface IAllocationsState {
    readonly upcomingEvents: IEvent[];
    readonly eventsForYear: IEvent[];
    readonly pendingEvents: IEvent[];
}

interface IAddPendingEvent {
    readonly event: IEvent;
    readonly autoApproved: boolean;
}

interface IRemoveReleasedAllocation {
    readonly allocationId: number;
    readonly autoApproved: boolean;
}

const initialState = {
    upcomingEvents: [] as IEvent[],
    eventsForYear: [] as IEvent[],
    pendingEvents: [] as IEvent[],
} as IAllocationsState;

const getEventsForYear = createAsyncThunk<IEvent[] | undefined, GetEventsForYearAPIRequest, {rejectValue: AxiosError}>(
    'allocations/getEventsForYear',
    async (data, {rejectWithValue}) => {
        try {
            const response = await api.getEventsForYear(data);
            return response.data.events;
        } catch (err) {
            return rejectWithValue(err as AxiosError);
        }
    }
);

const getUpcomingEvents = createAsyncThunk<IEvent[] | undefined, GetUpcomingEventsAPIRequest, {rejectValue: AxiosError}>(
    'allocations/getUpcomingEvents',
    async (data, {rejectWithValue}) => {
        try {
            const response = await api.getUpcomingEvents(data);
            return response.data.events;
        } catch (err) {
            return rejectWithValue(err as AxiosError);
        }
    }
);

const createAllocation = createAsyncThunk<IEvent | undefined, CreateAllocationAPIRequest, {rejectValue: AxiosError}>(
    'allocations/createAllocation',
    async (data, {rejectWithValue}) => {
        try {
            const response = await api.createAllocation(data);
            return response.data.allocation;
        } catch (err) {
            return rejectWithValue(err as AxiosError);
        }
    }
);

const updateAllocation = createAsyncThunk<IEvent | undefined, UpdateAllocationAPIRequest, {rejectValue: AxiosError}>(
    'allocations/updateAllocation',
    async (data, {rejectWithValue}) => {
        try {
            const response = await api.updateAllocation(data);
            return response.data.allocation;
        } catch (err) {
            return rejectWithValue(err as AxiosError);
        }
    }
);

const deleteAllocation = createAsyncThunk<DeleteAllocationAPIRequest | undefined, DeleteAllocationAPIRequest, {rejectValue: AxiosError}>(
    'allocations/deleteAllocation',
    async (data, {rejectWithValue}) => {
        try {
            await api.deleteAllocation(data);
            return data;
        } catch (err) {
            return rejectWithValue(err as AxiosError);
        }
    }
);

const getPendingEvents = createAsyncThunk<IEvent[] | undefined, GetPendingAllocationRequestsAPIRequest, {rejectValue: AxiosError}>(
    'allocations/getPendingEvents',
    async (data, {rejectWithValue}) => {
        try {
            const response = await api.getPendingRequests(data);
            return response.data.events;
        } catch (err) {
            return rejectWithValue(err as AxiosError);
        }
    }
);

const allocationsSlice = createSlice({
    name: "allocations",
    initialState,
    reducers: {
        addPendingEvent: (state, action: PayloadAction<IAddPendingEvent>) => {
            state.upcomingEvents = action.payload.autoApproved ? [...state.upcomingEvents, action.payload.event] : state.upcomingEvents;
            state.eventsForYear = action.payload.autoApproved ? [...state.eventsForYear, action.payload.event] : state.eventsForYear;
            state.pendingEvents = action.payload.autoApproved ? state.pendingEvents : [...state.pendingEvents, action.payload.event];
        },
        removeReleasedAllocation: (state, action: PayloadAction<IRemoveReleasedAllocation>) => {
            state.upcomingEvents = action.payload.autoApproved ? state.upcomingEvents.filter(event => event.id !== action.payload.allocationId) : state.upcomingEvents;
            state.eventsForYear = action.payload.autoApproved ? state.eventsForYear.filter(event => event.id !== action.payload.allocationId) : state.eventsForYear;
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(getEventsForYear.fulfilled, (state, action) => {
            if (action.payload) {
                state.eventsForYear = action.payload;
            }
        })
        .addCase(getUpcomingEvents.fulfilled, (state, action) => {
            if (action.payload) {
                state.upcomingEvents = action.payload;
            }
        })
        .addCase(createAllocation.fulfilled, (state, action) => {
            if (action.payload) {
                state.eventsForYear.push(action.payload);
                state.upcomingEvents.push(action.payload);
            }
        })
        .addCase(updateAllocation.fulfilled, (state, action) => {
            if (action.payload) {
                state.eventsForYear = findAndUpdate(state.eventsForYear, action.payload);
                state.upcomingEvents = findAndUpdate(state.upcomingEvents, action.payload);
            }
        })
        .addCase(deleteAllocation.fulfilled, (state, action) => {
            if (action.payload) {
                state.eventsForYear.filter(e => e.id !== action.payload!.allocationId);
                state.upcomingEvents.filter(e => e.id !== action.payload!.allocationId);
            }
        })
        .addCase(getPendingEvents.fulfilled, (state, action) => {
            if (action.payload) {
                state.pendingEvents = action.payload;
            }
        })
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .addCase(resourcesSlice.actions.selectResource, (state, action) => {
            return initialState;
        })
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .addCase(authSlice.actions.tokenRefreshFailure, (state, action) => {
            return initialState;
        })
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .addCase(logout.fulfilled, (state, action) => {
            return initialState;
        })
    }
});

export default allocationsSlice;

export {
    getEventsForYear,
    getUpcomingEvents,
    createAllocation,
    updateAllocation,
    deleteAllocation,
    getPendingEvents,
    initialState
};

import {createSlice} from "@reduxjs/toolkit";

interface INavigationState {
    readonly menuToggled: boolean;
}

const initialState = {
    menuToggled: false,
} as INavigationState;

const navigationSlice = createSlice({
    name: "navigation",
    initialState,
    reducers: {
        toggleMenuOn: (state) => {
            state.menuToggled = true;
        },
        toggleMenuOff: (state) => {
            state.menuToggled = false;
        }
    }
});

export default navigationSlice;

export {
    initialState
};

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosError } from "axios";

import api from "../../api/api";
import {
    ActivateUserAPIRequest,
    LoginAPIRequest,
    ReactivateUserAPIRequest,
    ResetPasswordAPIRequest,
    SendPasswordResetLinkAPIRequest,
    UpdateCurrentUserAPIRequest
} from "../../api/types";
import {deleteToken, hasValidToken, saveToken, TokenTypes} from "./tokenStorage";

interface IAuthState {
    readonly authenticated: boolean;
    readonly logoutInitiated: boolean;
    readonly redirectToLogin: boolean;
}

const initialState = {
    authenticated: hasValidToken(), // Ensure that only valid tokens are accepted
    logoutInitiated: false,
    redirectToLogin: false
} as IAuthState;

const login = createAsyncThunk<string | undefined, LoginAPIRequest, {rejectValue: AxiosError}>(
    'auth/login',
    async (data, {rejectWithValue}) => {
        try {
            const response = await api.login(data);

            saveToken(TokenTypes.ACCESS_TOKEN, response.data.accessToken);
            saveToken(TokenTypes.REFRESH_TOKEN, response.data.refreshToken);
            
            return response.data.tokenType;
        } catch (err) {
            deleteToken(TokenTypes.ACCESS_TOKEN);
            deleteToken(TokenTypes.REFRESH_TOKEN);

            return rejectWithValue(err as AxiosError);
        }
    }
);

const logout = createAsyncThunk<void, void, {rejectValue: AxiosError}>(
    'auth/logout',
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async (data, {rejectWithValue}) => {
        try {
            await api.logout();

            deleteToken(TokenTypes.ACCESS_TOKEN);
            deleteToken(TokenTypes.REFRESH_TOKEN);            
        } catch (err) {
            return rejectWithValue(err as AxiosError);
        }
    }
);

const changePassword = createAsyncThunk<void, UpdateCurrentUserAPIRequest, {rejectValue: AxiosError}>(
    'auth/changePassword',
    async (data, {rejectWithValue, dispatch}) => {
        try {
            const response = await api.updateCurrentUser(data);
            await dispatch(login({
                email: response.data.email,
                password: data.newPassword!
            }));
        } catch (err) {
            return rejectWithValue(err as AxiosError);
        }
    }
);

const forgottenPassword = createAsyncThunk<void, SendPasswordResetLinkAPIRequest, {rejectValue: AxiosError}>(
    'auth/forgottenPassword',
    async (data, {rejectWithValue}) => {
        try {
            await api.sendPasswordResetLink(data);        
        } catch (err) {
            return rejectWithValue(err as AxiosError);
        }
    }
);

const resetPassword = createAsyncThunk<string | undefined, ResetPasswordAPIRequest, {rejectValue: AxiosError}>(
    'auth/resetPassword',
    async (data, {rejectWithValue}) => {
        try {
            const response = await api.resetPassword(data);        

            saveToken(TokenTypes.ACCESS_TOKEN, response.data.accessToken);
            saveToken(TokenTypes.REFRESH_TOKEN, response.data.refreshToken);

            return response.data.tokenType;
        } catch (err) {
            return rejectWithValue(err as AxiosError);
        }
    }
);

const activateUser = createAsyncThunk<string | undefined, ActivateUserAPIRequest, {rejectValue: AxiosError}>(
    'auth/activateUser',
    async (data, {rejectWithValue}) => {
        try {
            const response = await api.activateUser(data);        

            saveToken(TokenTypes.ACCESS_TOKEN, response.data.accessToken);
            saveToken(TokenTypes.REFRESH_TOKEN, response.data.refreshToken);

            return response.data.tokenType;
        } catch (err) {
            return rejectWithValue(err as AxiosError);
        }
    }
);

const reactivateUser = createAsyncThunk<string | undefined, ReactivateUserAPIRequest, {rejectValue: AxiosError}>(
    'auth/reactivateUser',
    async (data, {rejectWithValue}) => {
        try {
            const response = await api.reactivateUser(data);        

            saveToken(TokenTypes.ACCESS_TOKEN, response.data.accessToken);
            saveToken(TokenTypes.REFRESH_TOKEN, response.data.refreshToken);

            return response.data.tokenType;
        } catch (err) {
            return rejectWithValue(err as AxiosError);
        }
    }
);

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        tokenRefreshFailure: (state) => {
            state.authenticated = false;
            state.logoutInitiated = false;
            state.redirectToLogin = false;
        }
    },
    extraReducers: (builder) => {
        builder
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .addCase(login.pending, (state, action) => {
            state.redirectToLogin = false;
        })
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .addCase(login.fulfilled, (state, action) => {
            state.authenticated = true;
        })
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .addCase(login.rejected, (state, action) => {
            state.authenticated = false;
        })
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .addCase(logout.pending, (state, action) => {
            state.logoutInitiated = true;
        })
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .addCase(logout.fulfilled, (state, action) => {
            state.redirectToLogin = true;
            state.authenticated = false;
            state.logoutInitiated = false;
        })
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .addCase(logout.rejected, (state, action) => {
            state.logoutInitiated = false;
            state.authenticated = false;
            state.redirectToLogin = true;
        })
        .addCase(resetPassword.fulfilled, (state, action) => {
            if (action.payload) {
                state.authenticated = true;
            }
        })
        .addCase(activateUser.fulfilled, (state, action) => {
            if (action.payload) {
                state.authenticated = true;
            }
        })
        .addCase(reactivateUser.fulfilled, (state, action) => {
            if (action.payload) {
                state.authenticated = true;
            }
        })
    }
});

export default authSlice;

export {
    login,
    logout,
    changePassword,
    forgottenPassword,
    resetPassword,
    activateUser,
    reactivateUser,
    initialState
};
